import { CallStateEnum } from '@models/call-state.model';
import { AuthUserModel, LoggedInStateModel } from '@models/auth.model';

export interface IAuthData {
  authUser: AuthUserModel;
  loggedInState: LoggedInStateModel;
  impersonatedModerator: AuthUserModel;
  codeVerificationCallState: CallStateEnum;
  resendCodeVerificationCallState: CallStateEnum;
  resetPasswordCallState: CallStateEnum;
  changeDeviceStatusCallState: CallStateEnum;
  authUserCallState: CallStateEnum;
  signInCallState: CallStateEnum;
  createAccountCallState: CallStateEnum;
  logOutCallState: CallStateEnum;
  signInCallStateError: Error;
}

export const AUTH_DATA_INITIAL_DATA: IAuthData = {
  authUser: null,
  loggedInState: null,
  impersonatedModerator: null,
  codeVerificationCallState: CallStateEnum.Init,
  resendCodeVerificationCallState: CallStateEnum.Init,
  resetPasswordCallState: CallStateEnum.Init,
  changeDeviceStatusCallState: CallStateEnum.Init,
  authUserCallState: CallStateEnum.Init,
  logOutCallState: CallStateEnum.Init,
  signInCallState: CallStateEnum.Init,
  createAccountCallState: CallStateEnum.Init,
  signInCallStateError: null
};
