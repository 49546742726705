import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState
} from '@ngrx/signals';
import { CallStateEnum } from '@models/call-state.model';
import { AUTH_DATA_INITIAL_DATA, IAuthData } from '@app/store/auth/auth.model';
import { EMPTY, pipe, switchMap } from 'rxjs';
import {
  AuthUserModel,
  AuthUserRoleEnum,
  ILoginReqBody,
  LoggedInStateModel,
  UserLoggedInReasonEnum,
  VerificationOptionEnum
} from '@models/auth.model';
import { USER_ID_KEY, USER_TOKEN_KEY } from '@utils/storage.utils';
import { computed, inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AccountService } from '@api/account.service';
import {
  LocalStorageService,
  SessionStorageService
} from '@services/abstract-storage.service';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tap } from 'rxjs/operators';
import { tapResponse } from '@ngrx/operators';
import { RoutePaths } from '@utils/route.utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DeviceVerificationDialogComponent } from '@app/pages/auth/features/components/device-verification-dialog/device-verification-dialog.component';
import { HttpErrorMessageEnum } from '@models/http.model';
import { ICreateAccountReqBody } from '@models/account-form.model';
import { getAuthUser } from '@app/store/utils/auth.utils';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { Location } from '@angular/common';
import { FormConfigsStore } from '@app/store/form-configs/form-configs.store';

export const AuthStore = signalStore(
  { providedIn: 'root' },
  withDevtools('auth'),
  withState<IAuthData>(AUTH_DATA_INITIAL_DATA),
  withComputed(
    (
      { signInCallState, logOutCallState, authUser, impersonatedModerator },
      formConfigsStore = inject(FormConfigsStore)
    ) => ({
      isSignInError: computed(() => signInCallState() === CallStateEnum.Error),
      isSignInLoading: computed(
        () => signInCallState() === CallStateEnum.Loading
      ),
      isLogOutLoading: computed(
        () => logOutCallState() === CallStateEnum.Loading
      ),
      isAaVersionCompatible: computed(
        () =>
          authUser()?.aaVersion !==
          formConfigsStore.profileFormDataConfigs()?.configs.aaVersion
      ),
      isImpersonated: computed(() => !!impersonatedModerator())
    })
  ),
  withMethods(
    (
      store,
      authService = inject(AuthService),
      accountService = inject(AccountService),
      sessionStorageService = inject(SessionStorageService),
      matSnackBar = inject(MatSnackBar),
      translateService = inject(TranslateService),
      router = inject(Router),
      matDialog = inject(MatDialog),
      localStorageService = inject(LocalStorageService)
    ) => ({
      loadAuthUser: rxMethod<void>(
        pipe(
          tap(() =>
            patchState(store, { authUserCallState: CallStateEnum.Loading })
          ),
          switchMap(() =>
            getAuthUser(
              authService,
              accountService,
              sessionStorageService
            ).pipe(
              tapResponse({
                next: (authUser) =>
                  patchState(store, {
                    authUser,
                    impersonatedModerator:
                      authService.decodedUserAccount.role !==
                      AuthUserRoleEnum.Customer
                        ? authService.decodedUserAccount
                        : null,
                    authUserCallState: CallStateEnum.Loaded,
                    loggedInState: new LoggedInStateModel(
                      true,
                      UserLoggedInReasonEnum.StandAlone
                    )
                  }),
                error: () => {
                  matSnackBar.open(
                    translateService.instant(
                      'validation_device_update_device_status_error'
                    )
                  );
                  authService.removeTokens();
                  router.navigate([RoutePaths.Core.Home.absolutePath]);
                  patchState(store, {
                    authUserCallState: CallStateEnum.Error,
                    authUser: null,
                    impersonatedModerator: null,
                    loggedInState: new LoggedInStateModel(
                      false,
                      UserLoggedInReasonEnum.StandAlone
                    )
                  });
                }
              })
            )
          )
        )
      ),
      signIn: rxMethod<{
        reqBody: ILoginReqBody;
        remember: boolean;
      }>(
        pipe(
          tap(() => {
            patchState(store, { signInCallState: CallStateEnum.Loading });
          }),
          switchMap(({ reqBody, remember }) =>
            accountService.login(reqBody, remember).pipe(
              tapResponse({
                next: (authUser: AuthUserModel) => {
                  if (authUser.deviceHash === authService.getDeviceHash()) {
                    authService.setDeviceHash(
                      authUser.deviceHash,
                      authUser.token
                    );

                    authService.logIn(authUser.token);
                    router
                      .navigate([RoutePaths.Core.My.Services.absolutePath])
                      .then();
                  } else {
                    authService.setDeviceHash(
                      authUser.deviceHash,
                      authUser.token
                    );

                    matDialog.open(DeviceVerificationDialogComponent);
                  }
                  patchState(store, {
                    signInCallState: CallStateEnum.Loaded,
                    authUser,
                    loggedInState: new LoggedInStateModel(
                      true,
                      UserLoggedInReasonEnum.UserAction
                    )
                  });
                },
                error: () =>
                  patchState(store, {
                    signInCallStateError: new Error(
                      'home_login_req_error_message'
                    ),
                    signInCallState: CallStateEnum.Error
                  })
              })
            )
          )
        )
      ),
      impersonate: rxMethod<{ accountId: string; token: string }>(
        pipe(
          switchMap(({ accountId, token }) => {
            authService.removeTokens();
            authService.removeDeviceHash();

            sessionStorageService.setItem(USER_ID_KEY, accountId);
            localStorageService.setItem(USER_TOKEN_KEY, token);

            return getAuthUser(
              authService,
              accountService,
              sessionStorageService
            ).pipe(
              tapResponse({
                next: (authUser) => {
                  patchState(store, {
                    authUser,
                    loggedInState: new LoggedInStateModel(
                      true,
                      UserLoggedInReasonEnum.StandAlone
                    ),
                    impersonatedModerator: authUser
                  });

                  if (authUser) {
                    router.navigate([RoutePaths.Core.My.absolutePath], {
                      replaceUrl: true
                    });
                  } else {
                    router.navigate([RoutePaths.Core.Home.absolutePath]);
                  }
                },
                error: () =>
                  patchState(store, {
                    signInCallStateError: new Error(
                      'home_login_req_error_message'
                    ),
                    signInCallState: CallStateEnum.Error
                  })
              })
            );
          })
        )
      ),
      resendVerificationCode: rxMethod<void>(
        pipe(
          tap(() => {
            patchState(store, {
              resendCodeVerificationCallState: CallStateEnum.Loading
            });
          }),
          switchMap(() =>
            accountService
              .resendDeviceVerificationCode({
                accountId: store.authUser()?.id,
                jwt: store.authUser()?.token
              })
              .pipe(
                tapResponse({
                  next: () =>
                    patchState(store, {
                      resendCodeVerificationCallState: CallStateEnum.Loaded
                    }),
                  error: ({ error }) => {
                    if (
                      error.message === HttpErrorMessageEnum.InvalidToken ||
                      error.message === HttpErrorMessageEnum.AccountNotFound
                    ) {
                      matSnackBar.open(
                        translateService.instant(
                          'validation_device_login_again'
                        )
                      );
                    } else {
                      matSnackBar.open(error.message);
                    }

                    patchState(store, {
                      resendCodeVerificationCallState: CallStateEnum.Error
                    });
                  }
                })
              )
          )
        )
      ),
      verifyCode: rxMethod<{
        verificationCode: string;
        tfaType: VerificationOptionEnum;
      }>(
        pipe(
          tap(() =>
            patchState(store, {
              codeVerificationCallState: CallStateEnum.Loading
            })
          ),
          switchMap(({ verificationCode, tfaType }) =>
            accountService
              .checkDeviceVerificationCode({
                accountId: store.authUser()?.id,
                jwt: store.authUser()?.token,
                verificationCode,
                tfaType
              })
              .pipe(
                tapResponse({
                  next: ({ isValid }) => {
                    if (isValid) {
                      authService.logIn(store.authUser().token);
                      patchState(store, {
                        codeVerificationCallState: CallStateEnum.Loaded,
                        loggedInState: new LoggedInStateModel(
                          true,
                          UserLoggedInReasonEnum.UserAction
                        )
                      });
                    }

                    patchState(store, {
                      codeVerificationCallState: CallStateEnum.Loaded
                    });
                  },
                  error: () =>
                    patchState(store, {
                      codeVerificationCallState: CallStateEnum.Error
                    })
                })
              )
          )
        )
      ),
      changeDeviceStatus: rxMethod<void>(
        pipe(
          tap(() =>
            patchState(store, {
              changeDeviceStatusCallState: CallStateEnum.Loading
            })
          ),
          switchMap(() =>
            accountService
              .updateDeviceStatusToVerified(store.authUser().id)
              .pipe(
                tapResponse({
                  next: () => {
                    patchState(store, {
                      changeDeviceStatusCallState: CallStateEnum.Loaded
                    });
                    router.navigate([RoutePaths.Core.My.Services.absolutePath]);
                  },
                  error: () => {
                    matSnackBar.open(
                      translateService.instant(
                        'validation_device_update_device_status_error'
                      )
                    );
                    patchState(store, {
                      changeDeviceStatusCallState: CallStateEnum.Error
                    });
                  }
                })
              )
          )
        )
      ),
      logOut: rxMethod<{ removeDeviceHash: boolean }>(
        pipe(
          tap(() =>
            patchState(store, { logOutCallState: CallStateEnum.Loading })
          ),
          switchMap(({ removeDeviceHash }) => {
            const logOutAction = (removeDeviceHash: boolean) => {
              authService.logout(removeDeviceHash);
              patchState(store, {
                logOutCallState: CallStateEnum.Loaded,
                loggedInState: new LoggedInStateModel(
                  false,
                  UserLoggedInReasonEnum.UserAction
                )
              });
            };

            if (removeDeviceHash) {
              return accountService.logout(store.authUser().id, true).pipe(
                tapResponse({
                  next: () => {
                    logOutAction(true);
                  },
                  error: () =>
                    patchState(store, { logOutCallState: CallStateEnum.Error })
                })
              );
            }

            logOutAction(false);
            return EMPTY;
          })
        )
      ),
      resetPassword: rxMethod<{ email: string }>(
        pipe(
          tap(() =>
            patchState(store, { resetPasswordCallState: CallStateEnum.Loading })
          ),
          switchMap(({ email }) =>
            accountService
              .resetPassword({
                email
              })
              .pipe(
                tapResponse({
                  next: () => {
                    matSnackBar.open(
                      translateService.instant('reset_password_success_message')
                    );
                    patchState(store, {
                      resetPasswordCallState: CallStateEnum.Loaded
                    });
                  },
                  error: () =>
                    patchState(store, {
                      resetPasswordCallState: CallStateEnum.Error
                    })
                })
              )
          )
        )
      ),
      createAccount: rxMethod<ICreateAccountReqBody>(
        pipe(
          tap(() =>
            patchState(store, { createAccountCallState: CallStateEnum.Loading })
          ),
          switchMap(({ email, role, password }) =>
            accountService
              .createAccount({
                email,
                role,
                password
              })
              .pipe(
                tapResponse({
                  next: () => {
                    router.navigate([
                      RoutePaths.Core.Auth.SignUpSuccessful.absolutePath
                    ]);
                    patchState(store, {
                      createAccountCallState: CallStateEnum.Loaded
                    });
                  },
                  error: ({ error }) => {
                    patchState(store, {
                      createAccountCallState: CallStateEnum.Error
                    });

                    matSnackBar.open(error?.message);
                  }
                })
              )
          )
        )
      ),
      setAuthUser(authUser: AuthUserModel) {
        patchState(store, { authUser });
      },
      setLoginState(loggedInState: LoggedInStateModel) {
        patchState(store, {
          loggedInState
        });
      }
    })
  ),
  withHooks({
    onInit(
      { loadAuthUser },
      authService = inject(AuthService),
      location = inject(Location)
    ) {
      if (!location.path(false).startsWith(RoutePaths.Inject.absolutePath)) {
        authService.decodeUserAccount();
        if (authService.decodedUserAccount) {
          loadAuthUser();
        }
      }
    }
  })
);
